<template>
  <v-app-bar
    fixed
    color="#FFFFFF"
    height="56px"
    max-height="56px"
    class="headerBox px-3"
  >
    <template v-if="pantalla === 'identificate'">
      <v-spacer />
      <v-app-bar-title>
        <v-img src="@/static/unicco.png" height="23px" width="170px"></v-img>
      </v-app-bar-title>
      <v-spacer />
    </template>
    <template v-else>
        <v-row class="mx-0" justify="space-between" align="center">
          <v-col>
            <v-img src="@/static/unicco.png" height="23px" width="170px"></v-img>
          </v-col>
          <v-col v-if="mostrar" class="text-end pl-0" align-self="center">
            <font-awesome-icon icon="fa-regular fa-circle-user" size="md" class="primary--text text--darken-1 mr-2"/>
            <span class="subtitle-1 font-weight-bold text-uppercase black--text">{{userLoged()}}</span>
          </v-col>
        </v-row>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: [],
  data() {
    return {
      mostrar: false,
    };
  },
  computed: {
    pantalla() {
      return this.$store.state.pantalla;
    },
  },
  methods: {
    userLoged() {
      return localStorage.getItem('user');
    },
  },
  mounted() {
    setTimeout(() => {
      this.mostrar = true;
    }, 4000);
  },
};
</script>

<style lang='scss'>
.headerBox {
  box-shadow: 0 8px 10px rgba(175, 175, 175, 0.25) !important;
  z-index: 10 !important;
  text-align: center;
}
</style>
